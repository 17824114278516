<template>
  <div class="py-6 lg:py-24">
    <div class="">
      <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        <h2 class="text-2xl lg:text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Connect Greetmate to 1000+ Integrations.
        </h2>
        <dl class="col-span-2 grid grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2">
          <div v-for="feature in features" :key="feature.name">
            <dt class="text-base font-semibold leading-7 text-gray-900">
              <div class="pb-4" v-if="feature.icon">
                <RoundedIcon :icon="feature.icon"/>
              </div>
              <img v-else
                   class="group-hover:text-secondary w-16 h-16  p-1 rounded-lg shrink-0"
                   src="@/../images/zapier-official.svg" alt="Zapier Logo"
              />
              {{ feature.name }}
            </dt>
            <dd class="mt-1 text-base leading-7 text-gray-600">{{ feature.description }}</dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script setup>
import {mdiApi} from "@mdi/js";

import RoundedIcon from "@/Components/Marketing/RoundedIcon.vue";

const features = [
  {
    name: 'Zapier Integration',
    description:
        'Connect Greetmate to Zapier and automate your workflows with 1000+ apps. For example, send Slack notifications with Call Summaries, or integrate your favorite CRM.',
  },
  {
    name: 'POST/GET Webhook',
    description:
        'Create completely custom integrations with Greetmate using Webhooks, send and receive data and use it in your calls.',
    icon: mdiApi,
  },
]
</script>
